import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import { lazy } from "react";

import Login from "./pages/Accounts/Login";
import Loginm from "./pages/Accountsm/Login";
import InHouseCarList from "./pages/InHouseCar/InHouseCarList";
import CodeMng from "./pages/Common/CodeMng";
import UserMng from "./pages/Common/UserMng";
import GroupMng from "./pages/Common/GroupMng";
import MenuMng from "./pages/Common/MenuMng";
import AuthMng from "./pages/Common/AuthMng";
import AuthMenuMng from "./pages/Common/AuthMenuMng";
import AuthGroupMng from "./pages/Common/AuthGroupMng";
import ApprovalMng from "./pages/CompanyMng/ApprovalMng";
import GroupUserMng from "./pages/Common/GroupUserMng";
// import VendorReg from "./pages/CompanyMng/VendorReg";
// import RealVendorReg from "./pages/CompanyMng/RealVendorReg";
// import LoadAreaReg from "./pages/CompanyMng/LoadAreaReg";
// import VehicleReg from "./pages/CompanyMng/VehicleReg";
// import TransOrderReg from "./pages/TransOrderMng/TransOrderReg";
// import TransTotalList from "./pages/TransOrderMng/TransTotalList";
// import ArriveMapView from "./pages/TransOrderMng/ArriveMapView";
// import RejectRealVendorReg from "./pages/TransOrderMng/RejectRealVendorReg";
import { DynamicRoute } from "./utils/routes/DynamicRoute";
// import RejectVehicleNoReg from "./pages/TransOrderMng/RejectVehicleNoReg";
// import TransDailyClosing from "./pages/TransOrderMng/TransDailyClosing";

// import TransOrderHistory from "./pages/TransOrderMng/TransOrderHistory";
// import PurchaseOrderReg from "./pages/PurchaseMng/PurchaseOrderReg";
// import BargeOrderReg from "./pages/SeaScrapMng/BargeOrderReg";
// import ImportOrderReg from "./pages/SeaScrapMng/ImportOrderReg";
// import ShipmentOrderReg from "./pages/ShipmentMng/ShipmentOrderReg";
// import TransferOrderReg from "./pages/ShipmentMng/TransferOrderReg";
// import WasteOrderReg from "./pages/GeneralOrderMng/WasteOrderReg";
// import SelfMadeOrderReg from "./pages/GeneralOrderMng/SelfMadeOrderReg";
// import PortOrderReg from "./pages/GeneralOrderMng/PortOrderReg";
// import PlantTranferOrderReg from "./pages/GeneralOrderMng/PlantTranferOrderReg";
// import IncomingVehicleMng from "./pages/IncomingMng/IncomingVehicleMng";
// import WeighingResult from "./pages/CompanyMng/WeighingResult";
import Board from "./pages/Common/Board";
import BoardInfo from "./pages/CompanyMng/BoardInfo";
import MainPage from "./pages/MainPage";
import Layout from "./components/Layout/Layout";
import AppInfo from "./pages/AppInfo";
// import KakaotalkMng from "./pages/CompanyMng/KakaotalkMng";
// import WeighingMng from "./pages/CompanyMng/WeighingMng";
// import TransCostClosing from "./pages/ShipmentMng/TransCostClosing";

import scrapPage from "./pages/urgentDispatch/scrap";
import safetyPage from "./pages/urgentDispatch/safety";
import purchasePage from "./pages/urgentDispatch/purchase";
import shipmentAndEtcPage from "./pages/urgentDispatch/shipmentAndEtc";
import dispatchOrderPage from "./pages/weightSetting/dispatchOrder";
import appUsagePage from "./pages/weightSetting/appUsage";
import inhousePage from "./pages/weightSetting/inhouse";
import inhouseSettingPage from "./pages/weightSetting/inhouseSetting";
import reducePage from "./pages/weightSetting/reduce";
import resultPage from "./pages/resultInquery/result";
import useHistoryPage from "./pages/resultInquery/useHistory";
import resendPage from "./pages/resultInquery/resend";

const DSRouter = () => {
  return (
    <Router>
      <Routes>
        <Route exact path={"/login"} element={<Login />} />
        <Route exact path={"/loginm"} element={<Loginm />} />
        <Route exact path={"/appinfo"} element={<AppInfo />} />
        <Route
          exact
          path={"/InHouseCar"}
          element={
            <DynamicRoute>
              <InHouseCarList />
            </DynamicRoute>
          }
        />
        <Route
          path="*"
          element={
            <>
              <Layout>
                <Routes>
                  <Route path={"/"} element={<DynamicRoute></DynamicRoute>} />
                  <Route
                    path={"/Common/CodeMng"}
                    element={
                      <DynamicRoute>
                        <CodeMng />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/Common/UserMng"}
                    element={
                      <DynamicRoute>
                        <UserMng />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/Common/GroupMng"}
                    element={
                      <DynamicRoute>
                        <GroupMng />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/Common/MenuMng"}
                    element={
                      <DynamicRoute>
                        <MenuMng />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/Common/AuthMng"}
                    element={
                      <DynamicRoute>
                        <AuthMng />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/Common/AuthMenuMng"}
                    element={
                      <DynamicRoute>
                        <AuthMenuMng />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/Common/AuthGroupMng"}
                    element={
                      <DynamicRoute>
                        <AuthGroupMng />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/Common/GroupUserMng"}
                    element={
                      <DynamicRoute>
                        <GroupUserMng />
                      </DynamicRoute>
                    }
                  />
                  {/*
                  <Route
                    path={"/Common/Board"}
                    element={
                      <DynamicRoute>
                        <Board />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/CompanyMng/VendorReg"}
                    element={
                      <DynamicRoute>
                        <VendorReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/CompanyMng/RealVendorReg"}
                    element={
                      <DynamicRoute>
                        <RealVendorReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/CompanyMng/LoadAreaReg"}
                    element={
                      <DynamicRoute>
                        <LoadAreaReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/CompanyMng/VehicleReg"}
                    element={
                      <DynamicRoute>
                        <VehicleReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/CompanyMng/ApprovalMng"}
                    element={
                      <DynamicRoute>
                        <ApprovalMng />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/CompanyMng/BoardInfo"}
                    element={
                      <DynamicRoute>
                        <BoardInfo />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/CompanyMng/WeighingResult"}
                    element={
                      <DynamicRoute>
                        <WeighingResult />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/CompanyMng/KakaotalkMng"}
                    element={
                      <DynamicRoute>
                        <KakaotalkMng />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/CompanyMng/WeighingMng"}
                    element={
                      <DynamicRoute>
                        <WeighingMng />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/TransOrderMng/TransOrderReg"}
                    element={
                      <DynamicRoute>
                        <TransOrderReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/TransOrderMng/RejectRealVendorReg"}
                    element={
                      <DynamicRoute>
                        <RejectRealVendorReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/TransOrderMng/RejectVehicleNoReg"}
                    element={
                      <DynamicRoute>
                        <RejectVehicleNoReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/TransOrderMng/TransTotalList"}
                    element={
                      <DynamicRoute>
                        <TransTotalList />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/TransOrderMng/TransDailyClosing"}
                    element={
                      <DynamicRoute>
                        <TransDailyClosing />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/TransOrderMng/ArriveMapView"}
                    element={
                      <DynamicRoute>
                        <ArriveMapView />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/TransOrderMng/TransOrderHistory"}
                    element={
                      <DynamicRoute>
                        <TransOrderHistory />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/PurchaseMng/PurchaseOrderReg"}
                    element={
                      <DynamicRoute>
                        <PurchaseOrderReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/SeaScrapMng/BargeOrderReg"}
                    element={
                      <DynamicRoute>
                        <BargeOrderReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/SeaScrapMng/ImportOrderReg"}
                    element={
                      <DynamicRoute>
                        <ImportOrderReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/ShipmentMng/ShipmentOrderReg"}
                    element={
                      <DynamicRoute>
                        <ShipmentOrderReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/ShipmentMng/TransferOrderReg"}
                    element={
                      <DynamicRoute>
                        <TransferOrderReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/GeneralOrderMng/WasteOrderReg"}
                    element={
                      <DynamicRoute>
                        <WasteOrderReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/GeneralOrderMng/SelfMadeOrderReg"}
                    element={
                      <DynamicRoute>
                        <SelfMadeOrderReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/GeneralOrderMng/PortOrderReg"}
                    element={
                      <DynamicRoute>
                        <PortOrderReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/ShipmentMng/TransCostClosing"}
                    element={
                      <DynamicRoute>
                        <TransCostClosing />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/GeneralOrderMng/PlantTranferOrderReg"}
                    element={
                      <DynamicRoute>
                        <PlantTranferOrderReg />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/IncomingMng/IncomingVehicleMng"}
                    element={
                      <DynamicRoute>
                        <IncomingVehicleMng />
                      </DynamicRoute>
                    }
                  />
*/}

                  {/* 한특 메뉴 */}
                  <Route
                    path={"/urgentDispatch/scrap"}
                    element={
                      <DynamicRoute>
                        <scrapPage />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/urgentDispatch/safety"}
                    element={
                      <DynamicRoute>
                        <safetyPage />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/urgentDispatch/purchase"}
                    element={
                      <DynamicRoute>
                        <purchasePage />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/urgentDispatch/shipmentAndEtc"}
                    element={
                      <DynamicRoute>
                        <shipmentAndEtcPage />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/weightSetting/dispatchOrder"}
                    element={
                      <DynamicRoute>
                        <dispatchOrderPage />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/weightSetting/appUsage"}
                    element={
                      <DynamicRoute>
                        <appUsagePage />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/weightSetting/inhouse"}
                    element={
                      <DynamicRoute>
                        <inhousePage />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/weightSetting/inhouseSetting"}
                    element={
                      <DynamicRoute>
                        <inhouseSettingPage />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/weightSetting/reduce"}
                    element={
                      <DynamicRoute>
                        <reducePage />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/resultInquery/result"}
                    element={
                      <DynamicRoute>
                        <resultPage />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/resultInquery/useHistory"}
                    element={
                      <DynamicRoute>
                        <useHistoryPage />
                      </DynamicRoute>
                    }
                  />
                  <Route
                    path={"/resultInquery/resend"}
                    element={
                      <DynamicRoute>
                        <resendPage />
                      </DynamicRoute>
                    }
                  />
                </Routes>
              </Layout>
            </>
          }
        />
      </Routes>
    </Router>
  );
};

export default DSRouter;

const PageImport = (path, defaultPath = "./pages") => {
  return import(defaultPath + path + "")
    .then((module) => module.default)
    .catch((error) => {
      if (/not find module/.test(error.message)) {
        return import(defaultPath + "/404").then((module) => module.default);
      }
      throw error;
    });
};

export const PageImportLazy = (path, defaultPath = "./pages") => {
  console.log(defaultPath + path + "");
  return retryLazy(() => import(defaultPath + path + "").catch((error) => {}));
};

export const retryLazy = (componentImport) =>
  lazy(async () => {
    const pageAlreadyRefreshed = JSON.parse(
      window.localStorage.getItem("pageRefreshed") || "false"
    );
    try {
      const component = await componentImport();
      window.localStorage.setItem("pageRefreshed", "false");
      return component;
    } catch (error) {
      if (!pageAlreadyRefreshed) {
        window.localStorage.setItem("pageRefreshed", "true");
        return window.location.reload();
      }
      throw error;
    }
  });
